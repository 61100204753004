.search__container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: stretch;
  align-items: flex-start;
  width: 100%;
  max-width: 40rem;
  margin: 1rem auto 1rem;

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: center;
  }

  .logo {
    height: 2rem;
    @media (max-width: 640px) {
      height: 3rem;
    }
  }

  .search__bar__container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    .search__bar {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      position: relative;
      border: 1px solid #ccc;
      padding: 0;
      border-radius: 1rem;
      width: 100%;
      z-index: 1000;

      &.search--expand {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0px;
      }

      .search__inputButton {
        font-size: 1.25rem;
        color: #ccc;
      }
      .search__inputClear {
        font-size: 1.25rem;
        cursor: pointer;
        color: #aaa;
      }

      .search__form {
        display: flex;
        padding: 0 1rem 0;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        justify-content: stretch;

        .search__inputSearch {
          font-size: 1rem;
          height: 1rem;
          padding: 0.5rem 1rem 0.5rem 0;
          border: 0px solid #eee;
          flex: 1;

          fieldset {
            border: 0;
          }
          &:focus {
            outline: none;
          }
        }
      }

      .search__suggestions {
        position: absolute;
        height: auto;
        top: 2rem;
        left: -1px;
        background: #fff;
        width: 100%;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border: 1px solid #ccc;
        border-top: 0;
        max-height: 66vh;
        overflow-y: scroll;

        @media (max-width: 640px) {
          max-height: 36vh;
        }

        hr {
          border: 0;
          border-top: 1px solid #ccc;
          margin: 0 1rem;
          padding-bottom: 0.25rem;
          position: sticky;
          top: 0;
        }
        .search__suggestion {
          margin: 0;
          padding: 0.5rem 1rem;
          cursor: pointer;

          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 1rem;
          justify-content: stretch;

          &:hover {
            background: #eee;
          }
        }
      }
    }
    .search__examples {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      max-width: 40rem;
      margin: 0 auto;
      font-size: 0.75rem;

      @media (max-width: 640px) {
        justify-content: center;
        font-size: 11px;
        overflow-x: scroll;
        gap: 0.35rem;

        & > span {
          display: none;
        }
      }
    }
  }
}
