// SEARCH RESULTS
.search__results {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  flex-wrap: nowrap;
  gap: 0rem;
  overflow-y: hidden;
  padding: 0;
  margin: 1rem auto;
  width: 100%;
  max-width: 40rem;
  overscroll-behavior: none;
  overscroll-behavior-y: contain;

  .search__results__table {
    flex: 1;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .search__card {
    font-size: 1rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    min-width: 16rem;
    gap: 1rem;
    padding: 0;
    min-height: 1.5rem;
    flex-shrink: 0;
    flex-grow: 1;
    height: auto;
    // opacity: 0;

    &:nth-child(even) {
      background: #f4f4f4;
    }

    @keyframes moveIn {
      from {
        opacity: 0%;
        transform: translateX(0.5rem); // rotate(12deg);
      }
      to {
        opacity: 100%;
        transform: translateX(0%); // rotate(0deg);
      }
    }

    // &.moving {
    // background-color: coral;
    // }
    // &.editing {
    // background-color: cornflowerblue;
    // }

    .card__category {
      color: #000000;
      font-size: 0.75rem;
      text-transform: uppercase;
    }
    .card__description {
      font-size: 0.875rem;
      font-family: Raleway;
      color: #000000;
      flex: 1;
      padding-right: 1rem;
    }

    .card__command {
      text-align: center;
      width: 10rem;
      padding: 0.5rem;

      & p {
        text-align: center;
        font-family: "Roboto Slab";
        // font-size: 1.4rem;
        display: flex;
        justify-content: center;
        align-items: center;

        kbd {
          background-color: #00000022;
          margin: 0 0.25rem 0 0;
          padding: 0 0.25rem;
          min-width: 1rem;
          height: 1.5rem;
          overflow: hidden;
        }
      }
    }
    &.head {
      font-weight: 100;
      position: sticky;
      top: 0;
      background: #fff;
      border-bottom: 1px solid #ddd;
      text-transform: uppercase;
      flex: 0;

      .card__command,
      .card__description {
        font-size: 0.75rem !important;
      }
    }
  }
}
