$breakpoint: 30rem;

.search {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100vh;

  @media only screen and (max-width: $breakpoint) {
    max-height: calc(100vh - 4rem);
  }

  overflow: hidden;
  padding: 1rem;

  .search__title {
    font-size: 2rem;
    font-family: "Raleway";
    text-align: center;
    color: #888;
    font-weight: 100;

    b {
      color: #222;
    }
  }
}
