$breakpoint: 30rem;

html {
  max-height: calc(100vh - env(safe-area-inset-bottom));
  overflow-y: hidden;

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: hidden;
    font-family: "Open Sans";

    @media only screen and (max-width: $breakpoint) {
      min-height: -webkit-fill-available;
      height: calc(50vh - env(safe-area-inset-bottom));
    }
  }
}
